import { Injectable, signal } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import {
  IProfileDetails,
  IProfileDetailsResponse,
  IProfileDetailsWithPassword,
  IProfileFilterResponse,
} from './profile.model';
import { environment } from '@/environments/environment';
import { IHttpResponseObject } from '@/models/shared.models';
import { Observable, tap } from 'rxjs';
import { BaseHttpResponseModel } from '@/api/common/models/base-http-response.model';
import { PeopleColumnsModel } from '@/api/people/model/people-columns.model';
import { GetAllFiltersConfigModel } from '@/api/profile/models/get-all-filters-config.model';
import { ProfileMasterDataResponseModel } from '@/api/profile/models/profile-master-data-response.model';
import { CreateUserRequestModel } from '@/api/profile/models/create-user-request.model';
import { PeopleProfileByIdResponseModel } from '@/api/people/model/people-profiles.model';
import { CustomFieldsBulkActionsReqModel } from './models/custom-fields-bulk-actions-req.model';
import { UserRoleEnum } from '@/models/user-role.model';
import { PeopleFiltersWrapper } from '../people/model/people-search.model';
import { HierarchyFiltersModel } from './models/hierarchy-filters.model';
import { ManagerHierarchyResponseModel } from './models/manager-hierarchy-response.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends ApiBaseService {
  private ENDPOINT = `${environment.api}/v2/api/Profiles`;
  private ENDPOINT_V1 = `${environment.api}/api/Profiles`;

  impersonatedBy$ = signal<IProfileDetails>(null);
  currentProfile$ = signal<IProfileDetails>(null);

  getProfileDetails() {
    return this.http
      .get<IProfileDetailsResponse>(`${this.ENDPOINT}/Details`)
      .pipe(tap((x) => this.currentProfile$.set(x.Result)));
  }

  updateProfileDetails(
    profileDetails: IProfileDetailsWithPassword,
    changePassword: boolean,
  ) {
    const payload = changePassword
      ? profileDetails
      : {
          ...profileDetails,
          newPassword: null,
          confirmedPassword: null,
          oldPassword: null,
        };
    return this.http.post<IHttpResponseObject<boolean>>(
      `${this.ENDPOINT}/UpdateProfile`,
      payload,
    );
  }

  updateProfilePhoto(file: File) {
    const formData: FormData = new FormData();
    formData.append('ProfileImage', file, file.name);

    return this.http.post<IHttpResponseObject<boolean>>(
      `${this.ENDPOINT}/UpdateProfileImage`,
      formData,
    );
  }

  //TODO: @Alexa - make all of them optional or pass in array of things to include
  getAllFilters(
    companyId: number,
    includePlaybooks: boolean,
    includeDraftPlaybooks: boolean,
    includeAllCompanies: boolean,
    includeProfiles?: boolean,
    includeManagers?: boolean,
    incluedeCollections?: boolean,
  ): Observable<IProfileFilterResponse> {
    return this.http.get<IProfileFilterResponse>(
      `${this.ENDPOINT_V1}/GetAllFilters`,
      {
        params: {
          companyId: companyId,
          includePlaybooks: includePlaybooks,
          includeDraftPlaybooks: includeDraftPlaybooks,
          includeAllCompanies: includeAllCompanies,
          includeProfiles: includeProfiles,
          includeManagers: includeManagers,
          incluedeCollections: incluedeCollections,
        },
      },
    );
  }
  getAllFiltersByConfig(
    cfg: Pick<GetAllFiltersConfigModel, 'companyId'> &
      Partial<Omit<GetAllFiltersConfigModel, 'companyId'>>,
  ): Observable<IProfileFilterResponse> {
    return this.http.get<IProfileFilterResponse>(
      `${this.ENDPOINT_V1}/GetAllFilters`,
      {
        params: cfg,
      },
    );
  }
  deleteProfile(profileId: number, companyId: number) {
    return this.http.delete(this.ENDPOINT_V1 + '/' + 'DeleteProfile', {
      params: {
        profileId: profileId,
        companyId: companyId,
      },
    });
  }

  getPeopleColumnsByCompany(
    companyId: number,
  ): Observable<BaseHttpResponseModel<PeopleColumnsModel[]>> {
    return this.http.get<BaseHttpResponseModel<PeopleColumnsModel[]>>(
      this.ENDPOINT_V1 + '/' + 'GetPeopleColumns',
      { params: { companyId } },
    );
  }

  importUsers(formData: FormData): Observable<BaseHttpResponseModel<void>> {
    return this.http.post<BaseHttpResponseModel<void>>(
      this.ENDPOINT_V1 + '/' + 'ImportUsers',
      formData,
      {
        params: {
          companyId: this.storageService.getCompanyId(),
        },
      },
    );
  }

  getMasterDataForProfile(
    profileId: number = 0,
  ): Observable<BaseHttpResponseModel<ProfileMasterDataResponseModel>> {
    return this.http.get<BaseHttpResponseModel<ProfileMasterDataResponseModel>>(
      this.ENDPOINT_V1 + '/' + 'GetAllMasterDataForProfile',
      {
        params: {
          companyId: this.storageService.getCompanyId(),
          profileId,
        },
      },
    );
  }

  createUser(user: CreateUserRequestModel) {
    return this.http.post(this.ENDPOINT_V1 + '/' + 'CreateProfile', user);
  }

  updateUser(user: CreateUserRequestModel & { profileId: number }) {
    return this.http.put(this.ENDPOINT_V1 + '/' + 'UpdateProfile', user);
  }

  getProfileById(
    profileId: number,
  ): Observable<BaseHttpResponseModel<PeopleProfileByIdResponseModel>> {
    return this.http.get<BaseHttpResponseModel<PeopleProfileByIdResponseModel>>(
      this.ENDPOINT_V1 + '/' + 'GetProfileById',
      {
        params: {
          profileId,
          companyId: this.storageService.getCompanyId(),
        },
      },
    );
  }

  downloadCompanyTemplate(): Observable<BaseHttpResponseModel<string>> {
    return this.http.get<BaseHttpResponseModel<string>>(
      this.ENDPOINT_V1 + '/' + 'DownloadCompanyTemplate',
      {
        params: {
          companyId: this.storageService.getCompanyId(),
        },
      },
    );
  }

  editCustomFieldsBulkAction(
    profileIds: number[],
    customFieldsViewModel: CustomFieldsBulkActionsReqModel[],
  ): Observable<BaseHttpResponseModel<boolean>> {
    return this.http.post<BaseHttpResponseModel<boolean>>(
      this.ENDPOINT_V1 + '/' + 'BulkEditCustomFields',
      {
        profileIds,
        customFieldsViewModel,
        companyId: this.storageService.getCompanyId(),
      },
    );
  }

  editRoleBulkActions(
    role: UserRoleEnum,
    profileIds: number[],
  ): Observable<BaseHttpResponseModel<boolean>> {
    return this.http.post<BaseHttpResponseModel<boolean>>(
      this.ENDPOINT_V1 + '/' + 'BulkEditRoles',
      {
        role,
        profileIds,
        companyId: this.storageService.getCompanyId(),
      },
    );
  }

  deleteMany(profileIds: number[]): Observable<BaseHttpResponseModel<boolean>> {
    return this.http.post<BaseHttpResponseModel<boolean>>(
      this.ENDPOINT_V1 + '/' + 'BulkDeleteProfiles',
      {
        companyId: this.storageService.getCompanyId(),
        profileIds,
      },
    );
  }

  getManagerRepHierarchyMap(
    filters: Partial<HierarchyFiltersModel>,
  ): Observable<BaseHttpResponseModel<ManagerHierarchyResponseModel[]>> {
    return this.http.post<
      BaseHttpResponseModel<ManagerHierarchyResponseModel[]>
    >(this.ENDPOINT_V1 + '/' + 'GetManagerRepHierarchyForPeopleConsole', {
      companyId: this.storageService.getCompanyId(),
      filters,
    });
  }

  editTagsBulkAction(
    profileIds: number[],
    tagsModel: { tagId: number; name: string; isAdded: boolean }[],
  ): Observable<BaseHttpResponseModel<boolean>> {
    return this.http.post<BaseHttpResponseModel<boolean>>(
      this.ENDPOINT_V1 + '/' + 'BulkEditTags',
      {
        companyId: this.storageService.getCompanyId(),
        profileIds,
        tagsModel,
      },
    );
  }

  sendNotification(obj: {
    emailSignature: string;
    message: string;
    profiles: { id: number; label: string }[];
    filters: PeopleFiltersWrapper[];
  }): Observable<BaseHttpResponseModel<boolean>> {
    return this.http.post<BaseHttpResponseModel<boolean>>(
      this.ENDPOINT_V1 + '/' + 'SendNotification',
      { ...obj, companyId: this.storageService.getCompanyId() },
    );
  }

  resendInvite(profileId: number): Observable<BaseHttpResponseModel<boolean>> {
    return this.http.post<BaseHttpResponseModel<boolean>>(
      this.ENDPOINT_V1 + '/' + 'ResendInvite',
      {},
      { params: { profileId, companyId: this.storageService.getCompanyId() } },
    );
  }

  loginAs(profileId: number): Observable<BaseHttpResponseModel<string>> {
    return this.http.post<BaseHttpResponseModel<string>>(
      this.ENDPOINT_V1 + '/' + 'LoginAsProfile',
      {},
      {
        params: { profileId, companyId: this.storageService.getCompanyId() },
      },
    );
  }

  unlockUpload(companyId: number){
    return this.http.post<IHttpResponseObject<string>>(
      `${this.ENDPOINT_V1}/UnlockUpload?companyId=${companyId}`,
      null,
    );
  }
}
